import { graphql } from "gatsby";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";
import { previewFragments } from "@blocks/fragments";
import BlocksRenderer from "@components/BlocksRenderer";
import Footer from "@components/Footer";
import YearsCounter from "@components/YearsCounter";
import getPostData from "@utils/getPostData";
import useUIColorCheck from "@utils/useUIColorCheck";
//import ArrowLineAnimatedWeHelp from "@components/ArrowLineAnimatedWeHelp";
//import ArrowLineAnimatedClients from "@components/ArrowLineAnimatedClients";

import "./style.css";

const About = ({ data, preview, transitionStatus }) => {
  useUIColorCheck([transitionStatus === "entered"]);

  const about = getPostData("page", { data, preview });

  const pageTitle = data?.wpgraphql?.page.metaTagsAcf.pageTitle
    ? data?.wpgraphql?.page.metaTagsAcf.pageTitle +
      " | " +
      data?.site?.siteMetadata.title
    : data?.wpgraphql?.page.title;
  const pageDescription = data?.wpgraphql?.page?.metaTagsAcf?.pageDescription;
  const pageImage = data?.wpgraphql?.page?.metaTagsAcf?.pageImage?.mediaItemUrl;
  const yearsCounterDescription =
    data?.wpgraphql?.page?.aboutAcf?.yearsCounterDescription ?? "";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {pageImage && <meta property="og:image" content={pageImage} />}
      </Helmet>

      <div className="page about-page">
        <YearsCounter description={yearsCounterDescription} />
        <BlocksRenderer
          blocks={about.blocks}
          entered={transitionStatus === "entered"}
          template={"about"}
          url={data?.wpgraphql?.page?.uri}
        />
      </div>

      <Footer />
    </>
  );
};

export const query = graphql`
  query($wpId: ID!) {
    site {
      siteMetadata {
        title
      }
    }
    wpgraphql {
      page: page(id: $wpId, idType: DATABASE_ID) {
        uri
        title
        status
        slug
        content

        aboutAcf {
          yearsCounterDescription
        }

        metaTagsAcf {
          pageTitle
          pageDescription
          pageImage {
            mediaItemUrl
          }
        }

        blocks {
          ...Roll_Block
        }
      }
    }
  }
`;

export const previewQuery = gql`
  query($wpId: ID!) {
    wpgraphql {
      page: page(id: $wpId, idType: DATABASE_ID) {
        aboutAcf {
          yearsCounterDescription
        }
        blocks {
          ...Roll_Block
        }
        blocksPreview {
          ...Roll_Block
        }

        preview: revisions(first: 1, before: null) {
          nodes {
            uri
            title
            status
            slug
            content
          }
        }
      }
    }
  }

  ${previewFragments}
`;

export default About;
