import React, { useCallback, useEffect, useRef } from "react";
import { quadOut } from "eases";

import Grid from "@components/Grid";

import "./style.css";

const INITIAL_YEAR = 2008;
const COUNT_UP_DURATION = 4000;
const THIS_YEAR = new Date().getFullYear();

const YearsCounter = ({ description }) => {
  const animation = useRef({
    raf: null,
    progress: 0,
    startTime: null,
  });
  const year = useRef(INITIAL_YEAR);
  const yearElement = useRef(null);
  const countElement = useRef(null);

  const updateYear = useCallback(() => {
    yearElement.current.innerText = year.current;
    countElement.current.innerText = `${year.current - INITIAL_YEAR}y`;
  }, []);

  const animate = useCallback(
    currentTime => {
      if (!animation.current.startTime) {
        animation.current.startTime = currentTime;
      }

      const deltaTime = currentTime - animation.current.startTime;
      animation.current.progress = quadOut(
        Math.min(deltaTime / COUNT_UP_DURATION, 1)
      );

      year.current = Math.round(
        INITIAL_YEAR + (THIS_YEAR - INITIAL_YEAR) * animation.current.progress
      );

      updateYear();

      if (year.current < THIS_YEAR) {
        animation.current.raf = requestAnimationFrame(animate);
      }
    },
    [updateYear]
  );

  useEffect(() => {
    animation.current.raf = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animation.current.raf);
  }, []);

  return (
    <div className="years-counter header-color--light">
      <Grid.Container narrowMargins>
        <Grid.Row>
          <Grid.Cell
            mobileColumns={4}
            columns={5}
            offset={0}
            className="years-counter__year-wrapper"
          >
            <div ref={yearElement} className="years-counter__year">
              {year.current}
            </div>
          </Grid.Cell>
          <Grid.Cell
            mobileColumns={4}
            columns={7}
            offset={0}
            className="years-counter__count-wrapper"
          >
            <div ref={countElement} className="years-counter__count">
              {year.current - INITIAL_YEAR}y
            </div>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell
            mobileColumns={4}
            columns={7}
            offset={5}
            className="years-counter__bottom"
          >
            <div className="years-counter__description">{description}</div>
          </Grid.Cell>
        </Grid.Row>
      </Grid.Container>
    </div>
  );
};

export default YearsCounter;
